import React from "react";
import {
 Box,
 Container,
 Text,
 Button,
 Flex,
 Image,
 Divider,
 Link,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const PaymentSuccess = ({ service, carModel, amount }) => {
 const navigate = useNavigate();

 return (
  <Box bg="#f0f0f0" py={10} px={5}>
   <Container maxW="lg" bg="white" p={6} borderRadius="md" boxShadow="md">
    {/* Success Message */}
    <Flex direction="column" align="center" mb={4}>
     <Image
      src="https://example.com/success-icon.png" // Replace with your success icon image
      alt="Success"
      boxSize="100px"
      mb={4}
     />
     <Text fontSize="2xl" fontWeight="bold" color="green.500">
      Payment Successful!
     </Text>
     <Text fontSize="md" textAlign="center" mt={2} mb={6}>
      Your payment of ₹{amount} for {service} ({carModel}) has been successfully
      processed.
     </Text>
    </Flex>

    {/* Booking Summary */}
    <Box bg="gray.50" p={4} borderRadius="md" mb={6}>
     <Text fontWeight="bold" mb={2}>
      Booking Summary
     </Text>
     <Text>Service: {service}</Text>
     <Text>Car Model: {carModel}</Text>
     <Text>Total Amount Paid: ₹{amount}</Text>
    </Box>

    {/* Next Steps */}
    <Text textAlign="center" fontSize="lg" fontWeight="bold" mb={4}>
     What’s Next?
    </Text>
    <Text textAlign="center" mb={6}>
     Our team will contact you shortly to confirm your booking. If you have any
     queries, feel free to contact us.
    </Text>

    {/* Action Buttons */}
    <Flex justify="center" mb={6}>
     <Button colorScheme="blue" onClick={() => navigate("/bookings")} mr={4}>
      View Bookings
     </Button>
     <Button variant="outline" onClick={() => navigate("/")}>
      Go to Home
     </Button>
    </Flex>

    {/* Support Information */}
    <Divider mb={6} />
    <Text textAlign="center" fontSize="sm">
     Need help?{" "}
     <Link href="mailto:support@carexpert.org.in" color="blue.500">
      Contact our support team
     </Link>
     .
    </Text>
   </Container>
  </Box>
 );
};

export default PaymentSuccess;
