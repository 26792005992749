import {
 Accordion,
 AccordionButton,
 AccordionItem,
 AccordionPanel,
 Box,
 Button,
 Divider,
 Flex,
 FormControl,
 FormLabel,
 Grid,
 GridItem,
 Heading,
 Image,
 Input,
 ListItem,
 Modal,
 ModalBody,
 ModalContent,
 ModalFooter,
 ModalHeader,
 ModalOverlay,
 Text,
 Textarea,
 UnorderedList,
 useDisclosure,
 useToast,
 Radio,
 RadioGroup,
} from "@chakra-ui/react";
import banner from "../../Assets/images/service_banner.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./swiper.css";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import axios from "axios";
import img1 from "../../Assets/images/Carousel/1.jpg";
import img2 from "../../Assets/images/Carousel/2.jpg";
import img3 from "../../Assets/images/Carousel/3.jpg";
import img4 from "../../Assets/images/Carousel/4.jpg";
import img5 from "../../Assets/images/Carousel/5.jpg";
import img6 from "../../Assets/images/Carousel/6.jpg";
import img7 from "../../Assets/images/Carousel/7.jpg";
import img8 from "../../Assets/images/Carousel/8.jpeg";
import img9 from "../../Assets/images/Carousel/9.jpeg";
import img10 from "../../Assets/images/Carousel/10.jpg";
import img11 from "../../Assets/images/Carousel/11.jpg";
import img12 from "../../Assets/images/Carousel/12.jpg";
import { useState, useEffect } from "react";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";

const Services = () => {
 const [categories, setCategories] = useState([]);
 const [selectedService, setSelectedService] = useState({
  service: "",
  carName: "",
  price: "",
 });
 const [formData, setFormData] = useState({
  Service: "",
  carModel: "",
  Price: "",
  name: "",
  mobile: "",
  email: "",
  address: "",
  city: "",
  date: "",
  time: "",
 });
 const [paymentMethod, setPaymentMethod] = useState(""); // For payment type selection
 const { isOpen, onOpen, onClose } = useDisclosure();
 const toast = useToast();

 const datas = [
  {
   id: 1,
   service: "DoorStep Car Wash",
   cars: [
    { name: "Hatch Back", price: "349", time: "25 Mins Taken" },
    { name: "Sedan", price: "400", time: "30 Mins Taken" },
    { name: "Muv", price: "400", time: "35 Mins Taken" },
    { name: "Suv", price: "500", time: "40 Mins Taken" },
   ],
   count: "Per Car",
   details: [
    "Exterior Pressure Wash",
    "Vacuum Cleaning",
    "Shampoo Wash",
    "Mats Cleaning",
    "DashBoard Polish",
    "Dust Cleaning",
   ],
  },
  {
   id: 2,
   service: "Interior Deep Cleaning",
   cars: [
    { name: "Hatch Back", price: "799", time: "60 Mins Taken" },
    { name: "Sedan", price: "899", time: "70 Mins Taken" },
    { name: "Muv", price: "999", time: "70 Mins Taken" },
    { name: "Suv", price: "1299", time: "80 Mins Taken" },
   ],
   count: "Per Car",
   details: [
    "Seats Drycleaning",
    "Roof,Floor DryCleaning",
    "Exterior Pressure Wash",
    "Vacuum Cleaning",
    "Mats Cleaning",
    "DashBoard Polish",
    "Dust Cleaning",
   ],
  },
  {
   id: 3,
   service: "Rubbing & Polish",
   cars: [
    { name: "Hatch Back", price: "799", time: "60 Mins Taken" },
    { name: "Sedan", price: "899", time: "70 Mins Taken" },
    { name: "Muv", price: "999", time: "70 Mins Taken" },
    { name: "Suv", price: "1299", time: "80 Mins Taken" },
   ],
   count: "Per Car",
   details: [
    "Exterior Rubbing With Machine",
    "Engine Cleaning",
    "Complete Polishing",
    "Vacuum Cleaning",
    "Pressure Wash",
    "Tyre Dressing",
   ],
  },
  {
   id: 4,
   service: "Deep All Round Spa Service Package",
   cars: [
    { name: "Hatch Back", price: "1399", time: "120 Mins Taken" },
    { name: "Sedan", price: "1499", time: "130 Mins Taken" },
    { name: "Muv", price: "1699", time: "130 Mins Taken" },
    { name: "Suv", price: "1799", time: "150 Mins Taken" },
   ],
   count: "Per Car",
   details: [
    "Combo Of Interior,Exterior & Wash",
    "Rubbing & Polish",
    "Interior Deep Cleaning",
    "Pressure Wash",
    "Complete Car Cleaning Interior & Exterior",
   ],
  },
 ];

 const getData = async () => {
  try {
   const res = await axios.get(
    "https://app-api.carexpert.org.in/api/categories/all"
   );
   console.log("check", res.data);
   setCategories(res.data);
  } catch (error) {
   console.log("errr", error);
  }
 };

 useEffect(() => {
  getData();
 }, []);

 const images = [
  [img1, img2, img3, img4],
  [img5, img6, img7, img8],
  [img9, img10, img11, img12],
 ];

 const handleChange = (e) => {
  const { name, value } = e.target;
  setFormData({
   ...formData,
   [name]: value,
  });
 };

 const handleSubmit = async (e) => {
  e.preventDefault();

  // Ensure payment method is selected
  if (!paymentMethod) {
   toast({
    title: "Payment Method Required",
    description: "Please select a payment method to proceed.",
    status: "error",
    duration: 5000,
    isClosable: true,
   });
   return;
  }

  try {
   // Determine the amount based on payment type
   let totalAmount = paymentMethod === "full" ? formData.Price : 99;

   // Step 1: Initiate payment API (PhonePe or any payment gateway)
   const paymentResponse = await axios.post(
    "https://app-api.carexpert.org.in/api/create-order", // Your backend payment initiation API
    {
     amount: totalAmount, // Amount to be paid in INR
     mobileNumber: formData.mobile, // Customer's mobile number
     name: formData.name, // Customer's name
    }
   );

   // If payment URL is returned, redirect to the payment page
   if (paymentResponse.data.url) {
    window.location.href = paymentResponse.data.url;
   } else {
    throw new Error("Payment initiation failed");
   }

   // Step 2: Send Emails after successful payment
   const emailResponse = await axios.post(
    "https://app-api.carexpert.org.in/api/contact", // Replace with your backend email API
    {
     name: formData.name,
     email: formData.email,
     mobile: formData.mobile,
     description: formData.description,
     bookingDate: formData.bookingDate,
     billingDetails: `Payment of INR ${totalAmount} has been made. Payment Method: ${paymentMethod}`,
    }
   );

   if (emailResponse.status === 200) {
    toast({
     title: "Submission Successful",
     description: "Your payment and email submission were successful.",
     status: "success",
     duration: 5000,
     isClosable: true,
    });
   } else {
    throw new Error("Email sending failed");
   }
  } catch (error) {
   console.error("Error during submission:", error);

   toast({
    title: "Submission Failed",
    description:
     error.message ||
     "There was an error with your submission. Please try again.",
    status: "error",
    duration: 5000,
    isClosable: true,
   });
  }
 };

 return (
  <>
   {/* The service banner */}
   <Box height={"100%"} width={"100%"}>
    <Image
     src={banner}
     alt="service banner"
     height={{ base: "40vh", md: "60vh" }}
     width={"100%"}
     objectFit={"cover"}
     filter={"brightness(0.8)"}
    />
   </Box>

   {/* Content for services */}
   <Flex
    py={12}
    flexDirection={"column"}
    width={{ base: "90%", md: "60%" }}
    margin={"auto"}
    gap={10}
   >
    <Heading
     textAlign={"center"}
     fontSize={{ base: "2rem", md: "2.4rem" }}
     color={"#000D6B"}
    >
     Snappy Doorstep Car Detailing Service{" "}
    </Heading>
    <Flex flexDirection={"column"} gap={2}>
     <Text
      fontSize={"1.1rem"}
      fontWeight={500}
      lineHeight={"1.6"}
      color={"gray.700"}
     >
      Car Detailing Service is a service that is utilized a lot these days and
      it can have various implications to various individuals.
     </Text>
     <Text
      fontSize={"1.1rem"}
      fontWeight={500}
      lineHeight={"1.6"}
      color={"gray.700"}
     >
      Car Detailing is to carefully clean, polish and protect all parts of a
      vehicle start to finish, all around, utilizing specialized tools and
      products.
     </Text>
    </Flex>
   </Flex>

   <Box
    width={"70%"}
    margin={"auto"}
    textAlign={"center"}
    mb={10}
    borderRadius={"lg"}
   >
    <Heading fontWeight={900} color={"#000D6B"}>
     Car Categories
    </Heading>
    <Grid
     pb={6}
     templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(4, 1fr)" }}
     gap={10}
     mt={20}
     justifyContent={"center"}
     alignItems={"center"}
    >
     {categories.map((ele, index) => (
      <GridItem
       py={2}
       key={ele.id}
       boxShadow={"lg"}
       borderRadius={"md"}
       overflow={"hidden"}
      >
       <Flex
        gap={2}
        alignItems={"center"}
        justifyContent={"center"}
        flexDirection={"column"}
       >
        <Text fontSize={"0.8rem"} fontWeight={700} height={"2vh"}>
         {ele.tag}
        </Text>
        <Image src={ele.categoryImage} alt={ele.id} height={"6vh"} />

        <Text>{ele.variantType}</Text>
        <Text>{ele.categoryName}</Text>
       </Flex>
      </GridItem>
     ))}
    </Grid>
   </Box>

   <Swiper
    spaceBetween={50}
    centeredSlides={true}
    autoplay={{
     delay: 3500,
     disableOnInteraction: false,
    }}
    pagination={{
     clickable: true,
     el: ".swiper-pagination",
     type: "bullets",
    }}
    modules={[Autoplay, Pagination, Navigation]}
    className="mySwiper"
   >
    {images.map((slideImages, index) => (
     <SwiperSlide key={index} style={{ background: "transparent" }}>
      <Flex
       gap={6}
       margin={"auto"}
       width="70%"
       flexDirection={{ base: "column", md: "row" }}
      >
       {slideImages.map((img, imgIndex) => (
        <Box
         key={imgIndex}
         width={{ base: "100%", lg: "50%" }}
         background="#FFFFFF"
         border="1px solid #d9dcde"
         rounded="md"
         py={2}
         px={2}
         boxShadow="md"
         height={300}
        >
         <Image
          src={img}
          alt={`img${imgIndex + 1}`}
          width="100%"
          height="auto"
         />
        </Box>
       ))}
      </Flex>
     </SwiperSlide>
    ))}
   </Swiper>

   <Grid
    pb={6}
    width={"70%"}
    margin={"auto"}
    templateColumns={"repeat(1, 1fr)"}
    gap={4}
    marginTop={20}
   >
    <Heading
     color={"#000D6B"}
     fontSize={{ base: "0.9", md: "2.2rem" }}
     textAlign={"center"}
    >
     Book A Service
    </Heading>
    <Accordion defaultIndex={[0]} allowToggle>
     {datas.map((service, serviceIndex) => (
      <AccordionItem width="100%" key={serviceIndex}>
       {({ isExpanded }) => (
        <>
         <h2>
          <AccordionButton
           _expanded={{ bg: "blue.100" }}
           border="3px solid"
           borderColor="#000D6B"
           borderRadius="md"
          >
           <Box as="span" flex="1" textAlign="left" color="#000D6B">
            {`${service.service} Service Package`}
           </Box>
           {isExpanded ? (
            <MinusIcon fontSize="12px" color="#000D6B" />
           ) : (
            <AddIcon fontSize="12px" color="#000D6B" />
           )}
          </AccordionButton>
         </h2>
         <AccordionPanel>
          <Grid
           width={{ base: "100%", md: "100%" }}
           templateColumns={"repeat(1, 1fr)"}
           gap={4}
          >
           <Grid
            templateColumns={{
             base: "repeat(1, 1fr)",
             md: "repeat(4, 1fr)",
            }}
            gap={4}
            width="100%"
           >
            {service.cars.map((car, index) => (
             <GridItem
              py={2}
              key={index}
              boxShadow="lg"
              borderRadius="md"
              overflow="hidden"
             >
              <Box
               bg="#000D6B"
               p={4}
               color="white"
               h={"14vh"}
               textAlign="center"
              >
               <Text
                fontSize={{
                 base: "1rem",
                 md: "1.2rem",
                }}
                fontWeight={900}
                mb={2}
                margin={"auto"}
               >
                {car.name}
               </Text>
               <Text
                fontSize={{
                 base: "0.8rem",
                 md: "1rem",
                }}
                fontWeight={700}
               >
                Rs {car.price} - {service.count}
               </Text>
              </Box>
              <Box py={6} bg="gray.50" height={{ base: "40vh", md: "30vh" }}>
               <UnorderedList
                spacing={2}
                fontSize={{
                 base: "0.8rem",
                 md: "0.9rem",
                }}
                fontWeight={500}
                color="gray.700"
               >
                {service.details.map((detail, i) => (
                 <ListItem key={i}>{detail}</ListItem>
                ))}
               </UnorderedList>
              </Box>
              <Flex
               bg="gray.50"
               py={1}
               flexDirection="column"
               alignItems="center"
              >
               <Divider border="1px solid grey" />
               <Text
                textAlign="center"
                fontSize="1rem"
                fontWeight={800}
                color="gray.700"
               >
                {car.time}
               </Text>
               <Button
                width={{ base: "50%", md: "40%" }}
                margin="auto"
                color="white"
                background="#000D6B"
                borderRadius="lg"
                onClick={() => {
                 setFormData({
                  Service: service.service,
                  carModel: car.name,
                  Price: car.price,
                 });
                 onOpen();
                }}
               >
                Book Now
               </Button>
              </Flex>
             </GridItem>
            ))}
           </Grid>
          </Grid>
         </AccordionPanel>
        </>
       )}
      </AccordionItem>
     ))}
    </Accordion>
   </Grid>
   <Modal isOpen={isOpen} onClose={onClose} size="xl">
    <ModalOverlay />
    <ModalContent maxW="60vw">
     <ModalHeader>Booking Form</ModalHeader>
     <ModalBody sx={{ width: "100%", backgroundColor: "transparent" }}>
      <Box
       as="form"
       onSubmit={handleSubmit}
       bg="white"
       p={6}
       borderRadius="lg"
       maxW="100%"
       mx="auto"
      >
       {/* Service Details Section */}
       <Heading size="md" mb={4} color="blue.600">
        Service Details
       </Heading>
       <FormControl isRequired mb={4}>
        <FormLabel fontWeight="bold" color="gray.600">
         Service
        </FormLabel>
        <Input
         type="text"
         name="service"
         value={formData.Service}
         readOnly
         bg="gray.50"
         borderRadius="md"
         focusBorderColor="blue.400"
        />
       </FormControl>

       <FormControl isRequired mb={4}>
        <FormLabel fontWeight="bold" color="gray.600">
         Car Model
        </FormLabel>
        <Input
         type="text"
         name="carModel"
         value={formData.carModel}
         readOnly
         bg="gray.50"
         borderRadius="md"
         focusBorderColor="blue.400"
        />
       </FormControl>

       <FormControl isRequired mb={4}>
        <FormLabel fontWeight="bold" color="gray.600">
         Price
        </FormLabel>
        <Input
         type="text"
         name="price"
         value={`Rs ${formData.Price}`}
         readOnly
         bg="gray.50"
         borderRadius="md"
         focusBorderColor="blue.400"
        />
       </FormControl>

       {/* Personal Information Section */}
       <Heading size="md" mb={4} mt={6} color="blue.600">
        Personal Information
       </Heading>
       <FormControl isRequired mb={4}>
        <FormLabel fontWeight="bold" color="gray.600">
         Name
        </FormLabel>
        <Input
         type="text"
         name="name"
         value={formData.name}
         onChange={handleChange}
         placeholder="Enter your name"
         borderRadius="md"
         focusBorderColor="blue.400"
        />
       </FormControl>

       <FormControl isRequired mb={4}>
        <FormLabel fontWeight="bold" color="gray.600">
         Mobile
        </FormLabel>
        <Input
         type="tel"
         name="mobile"
         value={formData.mobile}
         onChange={handleChange}
         placeholder="Enter your mobile number"
         borderRadius="md"
         focusBorderColor="blue.400"
        />
       </FormControl>

       <FormControl isRequired mb={4}>
        <FormLabel fontWeight="bold" color="gray.600">
         Email
        </FormLabel>
        <Input
         type="email"
         name="email"
         value={formData.email}
         onChange={handleChange}
         placeholder="Enter your email address"
         borderRadius="md"
         focusBorderColor="blue.400"
        />
       </FormControl>

       <FormControl isRequired mb={4}>
        <FormLabel fontWeight="bold" color="gray.600">
         Address
        </FormLabel>
        <Textarea
         name="address"
         value={formData.address}
         onChange={handleChange}
         placeholder="Enter your address"
         borderRadius="md"
         focusBorderColor="blue.400"
         bg="gray.50"
        />
       </FormControl>

       <FormControl isRequired mb={4}>
        <FormLabel fontWeight="bold" color="gray.600">
         City
        </FormLabel>
        <Input
         type="text"
         name="city"
         value={formData.city}
         onChange={handleChange}
         placeholder="Enter your city"
         borderRadius="md"
         focusBorderColor="blue.400"
        />
       </FormControl>

       <FormControl isRequired mb={4}>
        <FormLabel fontWeight="bold" color="gray.600">
         Date
        </FormLabel>
        <Input
         type="date"
         name="date"
         value={formData.date}
         onChange={handleChange}
         borderRadius="md"
         focusBorderColor="blue.400"
        />
       </FormControl>

       <FormControl isRequired mb={4}>
        <FormLabel fontWeight="bold" color="gray.600">
         Time
        </FormLabel>
        <Input
         type="time"
         name="time"
         value={formData.time}
         onChange={handleChange}
         borderRadius="md"
         focusBorderColor="blue.400"
        />
       </FormControl>

       {/* Payment Method Section */}
       <Heading size="md" mb={4} mt={6} color="blue.600">
        Payment Method
       </Heading>
       <FormControl isRequired mb={4}>
        <FormLabel fontWeight="bold" color="gray.600">
         Payment Method
        </FormLabel>
        <RadioGroup
         onChange={(value) => setPaymentMethod(value)}
         value={paymentMethod}
        >
         <Flex direction="column">
          <Radio value="full" colorScheme="blue" mb={2}>
           Full Payment (Pay total amount)
          </Radio>
          <Radio value="bookingFee" colorScheme="blue">
           Booking Fee (Pay Rs 99 now)
          </Radio>
         </Flex>
        </RadioGroup>
       </FormControl>

       {/* Conditional Payment Summary */}
       <Box mt={4} p={4} bg="gray.100" borderRadius="md">
        {paymentMethod === "full" ? (
         <Text fontWeight="bold" color="green.600">
          Total Amount to Pay: Rs {formData.Price}
         </Text>
        ) : (
         <Box>
          <Text fontWeight="bold" color="orange.600">
           Booking Fee: Rs 99
          </Text>
          <Text color="gray.600">
           Remaining Balance: Rs {formData.Price - 99}
          </Text>
         </Box>
        )}
       </Box>

       <ModalFooter>
        <Button
         colorScheme="blue"
         type="submit"
         mr={3}
         borderRadius="md"
         px={8}
         py={6}
        >
         Submit
        </Button>
        <Button
         variant="ghost"
         onClick={onClose}
         borderRadius="md"
         px={8}
         py={6}
        >
         Close
        </Button>
       </ModalFooter>
      </Box>
     </ModalBody>
    </ModalContent>
   </Modal>
  </>
 );
};

export default Services;
